/* 医生注册 */
<template>

  <div class="register wrapper">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="240px"
      class="pt-30"
      status-icon
      style="width: 60%; margin: 0 auto;"
    >
      <el-form-item label="姓名：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="姓名"/>
      </el-form-item>
      <el-form-item label="性別：" prop="sex">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio v-for="item in sexList" :key="item.id" :label="item.id">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="身份证号：" prop="idcard">
        <el-input v-model="ruleForm.idcard" placeholder="身份证号"/>
      </el-form-item>
      <el-form-item label="所在城市：" prop="cityId">
        <el-select class="mr-10" v-model="ruleForm.province" placeholder="省份" @change="searchCity">
          <el-option v-for="item in provinceList" :label="item.name" :value="item.id" :key="item.id" />
        </el-select>
        <el-select v-model="ruleForm.cityId" placeholder="城市">
          <el-option v-for="item in cityList" :label="item.name" :value="item.id" :key="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所属单位：" prop="organId">
        <el-select
          class="mr-10"
          v-model="ruleForm.organId"
          filterable
          remote
          reserve-keyword
          remote-show-suffix
          :remote-method="remoteMethod"
          :loading="loadingOrgan"
          placeholder="未选择"
        >
          <el-option v-for="item in organs" :label="item.name" :value="item.id" :key="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="学历：" prop="education">
        <el-select v-model="ruleForm.education" placeholder="未选择">
          <el-option v-for="item in educations" :label="item.desc" :value="item.name" :key="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="职务：" prop="job">
        <el-input v-model="ruleForm.job" placeholder="职务"/>
      </el-form-item>
<!--      <el-form-item label="加入时间："  prop="joinDate">-->
<!--        <el-date-picker-->
<!--          v-model="ruleForm.joinDate"-->
<!--          type="date"-->
<!--          label="加入时间"-->
<!--          placeholder="加入时间"-->
<!--          format="yyyy-MM-DD"-->
<!--          value-format="yyyy-MM-DD"-->
<!--          style="width: 100%"-->
<!--        />-->
<!--      </el-form-item>-->
      <el-form-item label="联系电话：" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="联系电话"/>
      </el-form-item>
      <el-form-item label="联系邮箱：" prop="email">
        <el-input v-model="ruleForm.email" placeholder="邮箱"/>
      </el-form-item>
<!--      <el-form-item label="是否全科医生：" prop="general">-->
<!--        <el-radio-group v-model="ruleForm.general">-->
<!--          <el-radio :label="true">是</el-radio>-->
<!--          <el-radio :label="false">否</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
<!--      <el-form-item v-for="(item, index) in ruleForm.certificates" :label="item.label+'：'" :key="item.type" :prop="'certificates' + index">-->
<!--        <el-upload-->
<!--          ref="upload"-->
<!--          class="avatar-uploader"-->
<!--          :action="basicUrl+'/api/dmz/DOCTOR/file/upload'"-->
<!--          :show-file-list="false"-->
<!--          :limit="1"-->
<!--          :on-success="(res) => handleExceed(res, index)"-->
<!--          :before-upload="beforeAvatarUpload"-->
<!--        >-->
<!--          <template #trigger>-->
<!--            <img v-if="item.url" :src="item.url" class="avatar" />-->
<!--            <i v-else class="avatar-uploader-icon el-icon-plus"></i>-->
<!--          </template>-->
<!--        </el-upload>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="专长：" prop="expertise">-->
<!--        <el-input v-model="ruleForm.expertise" placeholder="专长"/>-->
<!--      </el-form-item>-->
      <el-form-item label="简介：" prop="about">
        <el-input type="textarea" :rows="6" v-model="ruleForm.about" placeholder="简介"/>
      </el-form-item>
      <el-form-item label="" prop="agree">
        <el-checkbox  v-model="ruleForm.agree" label="我已认真阅读并同意" />
        <router-link to="/policy" tag="a" target="_blank" class="pl-30">使用条款和隐私政策</router-link>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleFormRef')" size="large" :loading="loading" :disabled="!ruleForm.agree" style="background-color: #1c69a2">注册</el-button>
        <el-button @click="resetForm('ruleFormRef')" size="large">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDistrict, getEducation, postDoctorRegister, getOrganizationList } from '@/api/index'

export default{
  name: "RegisterDoctor",
 data() {
  const validateCertificates = (rule, value, callback) => {
    let i = Number(rule.field.split('certificates')[1])
    let certificate = this.ruleForm.certificates[i]
    if (!certificate.path) {
      callback(new Error('请上传'+certificate.label))
    }else {
      callback()
    }
  }
   const validateEmail2=(rule, value, callback) =>{
     var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
     if(!(emailRegex.test(value)) && this.ruleForm.email ){
       callback(new Error('邮箱不合法，请重新输入'))
     }else {
       callback()
     }
   }
  return {
    loading: false,
    basicUrl : this.$basicUrl,
    sexList: [{id: 'MALE', name: '男'}, {id: 'FAMALE', name: '女'}, {id: 'OTHER', name: '其他'}],
    ruleForm: {
      province: '',
      organId: '',
      job: '',
      cityId: '',
      // certificates: [{label: '身份证(正面)', type: 'MIF', path: '', url: ''}, {label: '身份证(反面)', type: 'MIB', path: '', url: ''}, {label: '国家职业资格证书', type: 'NVQ', path: '', url: ''}, {label: '执业资格证书', type: 'PQC', path: '', url: ''}]
    },
    rules: {
      name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
      sex:  [{ required: true, message: '请选择性別', trigger: 'change'}],
      idcard: [{ required: true, message: '请填写身份证号', trigger: 'blur' }],
      licenseFrontPath: [{ required: true, message: '请上传许可证正面照片', trigger: 'blur' }],
      job: [{ required: true, message: '请填写职务', trigger: 'blur' }],
      general: [{ required: true, message: '请选择是否全科医生', trigger: 'change' }],
      phone: [{ required: true, message: '请填写联系电话', trigger: 'blur' }],
      email: [{ required: true,validator:validateEmail2,message: '请填写正确邮箱', trigger: 'blur' }],
      joinDate: [{ required: true, message: '请选择加入时间', trigger: 'change' }],
      expertise: [{ required: true, message: '请填写专长', trigger: 'blur' }],
      about: [{ required: false, message: '请填写简介', trigger: 'blur' }],
      province: [{ required: true, message: '请选择省份', trigger: 'change'}],
      organId: [{ required: true, message: '请选择所属单位', trigger: 'change'}],
      cityId: [{ required: true, message: '请选择城市', trigger: 'change'}],
      education: [{ required: false, message: '请选择学历', trigger: 'change'}],
      certificates0: [{ required: true, validator: validateCertificates, trigger: 'change' }],
      certificates1: [{ required: true, validator: validateCertificates, trigger: 'change' }],
      certificates2: [{ required: true, validator: validateCertificates, trigger: 'change' }],
      certificates3: [{ required: true, validator: validateCertificates, trigger: 'change' }],
    },
    educations: [],
    organs: [],
    loadingOrgan: false,
    provinceList: [],
    cityList: [],
    countyList: []
  }
 },
  mounted() {
    this.reqEducation()
    this.getOrgans('')
    this.reqDistrict('', 'PROVINCE')
  },
  methods: {
    async reqSubmit() {
      this.loading = true
      const res = await postDoctorRegister(this.ruleForm)
      if(res.msg.flag === 0) {
        this.$message.success(res.msg.message)
        this.resetForm()
        setTimeout(() => {
          this.$router.push('/')
        }, 2000)
      } else {
        this.$message.error(res.msg.message)
      }
      this.loading = false
    },
    submitForm(formEl) {
      console.log(formEl)
      this.$refs[formEl].validate((valid, fields) => {
        if (valid) {
          console.log('submit!')
          this.reqSubmit()
        } else {
          console.log('error submit!', fields)
        }
      })
    },
    resetForm() {
      this.$refs['ruleFormRef'].resetFields()
    },
    async reqEducation() {
      const res = await getEducation()
      this.educations = res.biz
    },
    async getOrgans (val) {
      this.loadingOrgan = true
      const res = await getOrganizationList({keyword: val})
      this.organs = res.biz
      this.loadingOrgan = false
    },
    remoteMethod(val) {
      if (val) {
        this.getOrgans(val)
      }
    },
    async reqDistrict(parentCode, level) {
      const res = await getDistrict({parentCode, level: level})
      if (level === 'PROVINCE') {
        this.provinceList = res.biz
      }
      if (level === 'CITY') {
        this.cityList = res.biz
        this.ruleForm.cityId = ''
      }
      
    },
    searchCity(val) {
      this.reqDistrict(val, 'CITY')
    },
    handleExceed(res, index) {
      console.log(res, index)
      if(res && res.msg.flag === 0) {
        this.ruleForm.certificates[index].path = res.biz.path
        this.ruleForm.certificates[index].url = res.biz.url
      }
      
    },
    beforeAvatarUpload(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        this.$message.error('请上传jpg或者png格式的图片')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        this.$message.error('图片文件大小不超过2MB!')
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss">

.avatar-uploader .el-upload {
  border: 1px dashed #ddd;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  i.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
  }
  .avatar {
    width: 128px;
    height: 128px;
    display: block;
  }
}
</style>
